import React, { createElement } from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual, isArray } from "lodash";

import sanitizeHtml from 'sanitize-html';

import 'react-tabs/style/react-tabs.css';


import * as PD from '../classes/pdStyle';
import {HtmlToText, cloneObject, getParam} from '../utils/utils';


import dsd from "../classes/clsDSD";

//import { Graphviz } from "graphviz-react";
//import * as d3Graphviz from 'd3-graphviz';



import ViewPackage from "../components/ViewPackage";
import ViewMetadata from "../components/ViewMetadata";
import ListActors from "../components/ListActors";
import ListSystems from "../components/ListSystems";
import ListUseCases from "../components/ListUseCases";
import ListAssociations from "../components/ListAssociations";



import { DesignerLayoutContext } from '../contexts/DesignerLayoutContext';

import Buffering from "../components/Buffering";
import D3Graphviz from "../components/D3Graphviz";

import { json2csv } from 'json-2-csv';

import JSZip from "jszip";
//import { graphviz } from "d3-graphviz";


class Package extends React.Component {

    static contextType = DesignerLayoutContext;

    constructor(props) {
        super(props);
        this.state = {
            mode: 'view',
            isLoaded: false,
            loadData:false
        };


        this.data = {};
        this.viz = null;
        this.vizEngine = "dot";

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMode = this.handleMode.bind(this);
        this.handleButton = this.handleButton.bind(this);
        this.handleVizClick = this.handleVizClick.bind(this);

        this.handleActorFilters = this.handleActorFilters.bind(this);
        this.handleSystemFilters = this.handleSystemFilters.bind(this);
        this.handleUseCaseFilters = this.handleUseCaseFilters.bind(this);
        this.handleAssociationFilters = this.handleAssociationFilters.bind(this);


        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.FallBackNavigation = `/packages`;

    }

    componentDidMount(){

       this.setup();

    }


    componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){

            if (this.state.vizNodeId){
                let partsVizNodeId = this.state.vizNodeId.split('_');
                switch(partsVizNodeId[0]){
                    case 'usecase':
                        if (partsVizNodeId.length > 1){
                            this.props.navigation(`/usecase/${partsVizNodeId[1]}`);
                        }
                        break;
                    case 'actor':
                        if (partsVizNodeId.length > 1){
                            this.props.navigation(`/actor/${partsVizNodeId[1]}`);
                        }
                        break;
                    case 'system':
                        if (partsVizNodeId.length > 1){
                            this.props.navigation(`/system/${partsVizNodeId[1]}`);
                        }
                        break;
                    case 'association':
                        if (partsVizNodeId.length > 1){
                            this.props.navigation(`/association/${partsVizNodeId[1]}`);
                        }
                        break;
            
                }

            }


            if (this.state.mode === 'new'){
                StateChange.isLoaded = true;
            }

            if (this.state.loadData){
                this.loadData(this.state.idPackage);
                StateChange.updateToggle = Number(!this.state.updateToggle)
                StateChange.loadData = false;
            }

            if (this.state.isUpdated){
                StateChange.isUpdated = false;
                StateChange.mode = 'view';
                StateChange.loadData = true;
            }
            else{
                if (this.state.idPackage != prevState.idPackage){
                    if (this.state.idPackage){
                        this.onUpdateNavigation = `/package/${this.state.idPackage}`;
                        StateChange.loadData = true;
                    }
                }
            }

            if (this.state.idPackage){
                for (var i in window.Breadcrumb){
                    if (window.Breadcrumb[i][0] === 'package'){
                        window.Breadcrumb = window.Breadcrumb.slice(0,i);
                        break;
                    }
                }
                this.Breadcrumb = JSON.parse(JSON.stringify(window.Breadcrumb));
    
                window.Breadcrumb.push(['logical model',`/package/${this.state.idPackage}`]);

                this.FallBackNavigation = `../packages`;
                this.onUpdateNavigation = this.FallBackNavigation;
    
            };

            this.setState(StateChange);

        }

    }
    
    loadData(id){

        if (id !== undefined){
            this.dsd.getPackage(id);
        }
        
        return;
        
    };

    setup(){


        let StateChange = {
            id: null,
            isLoaded: false,
            isUpdated: false,
            isFailed: false,
            loadData: false,

            FormData: {},
            Controls: {
                vizFullSize :true,
            },

            ActorFilters: {
                status:'in use'
            },

            SystemFilters: {
                status:'in use'
            },

            UseCaseFilters: {
                status:'in use'
            },

            AssociationFilters: {
                status:'in use'
            },


            countActors : null,
            countSystems : null,
            countSystems : null,
            countAssociations: null,

            Package: null,

            action: null,

            updateToggle: 0,

            vizNodeId: null,

            csv: null,
            rdfs: {},
            xsds:{},

        }

        StateChange.mode = getParam('mode', this.props);
        StateChange.idPackage = getParam('id', this.props);
        if (StateChange.idPackage){
            StateChange.loadData = true;
        }

        if (StateChange.mode == null){
            StateChange.mode = 'view';
        }

        this.setState(StateChange);
        
    }

    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let Controls = this.state.Controls;
        switch (FormFieldName){
            case 'vizFullSize':
                Controls[FormFieldName] = FormFieldValue;        
                this.setState({"Controls": Controls});
                break;
            default:
                let FormData = this.state.FormData;
                FormData[FormFieldName] = FormFieldValue;        
                this.setState({"FormData": FormData});
                break;                
        }
        return;

    };
    
    handleActorFilters(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let ActorFilters = this.state.ActorFilters;
        ActorFilters[FormFieldName] = FormFieldValue;
        this.setState({"ActorFilters": ActorFilters});

        return;

    };

    handleSystemFilters(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let SystemFilters = this.state.SystemFilters;
        SystemFilters[FormFieldName] = FormFieldValue;
        this.setState({"SystemFilters": SystemFilters});

        return;

    };

    handleUseCaseFilters(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let UseCaseFilters = this.state.UseCaseFilters;
        UseCaseFilters[FormFieldName] = FormFieldValue;
        this.setState({"UseCaseFilters": UseCaseFilters});

        return;

    };

    handleAssociationFilters(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let AssociationFilters = this.state.AssociationFilters;
        AssociationFilters[FormFieldName] = FormFieldValue;
        this.setState({"AssociationFilters": AssociationFilters});

        return;

    };


    handleSubmit(event) {

        switch (event.target.name){
            case 'btnCancel':
                switch (this.state.mode){
                    case 'new':
                        this.props.navigation(this.FallBackNavigation);
                        break;
                    case 'edit':
                        this.loadData(this.state.idPackage);
                        this.setState({mode: 'view'});
                        break;
        //            case 'remove':
        //                this.savviconfig.removeFactor(this.data.idProject,this.type,this.data.idFactor);
        //                break;  
                    default:
                        break; 
                };

                break;
            case 'btnSave':
            default:

                this.setState({
                    isUpdated: false,
                    isFailed: false,
                    invalidFields: false
                })
        

                if ('description' in this.state.FormData){
                    this.state.FormData.description = sanitizeHtml(this.state.FormData.description);
                }
                if ('proposals' in this.state.FormData){
                    this.state.FormData.proposals = sanitizeHtml(this.state.FormData.proposals);
                }

                switch (this.state.mode){
                    case 'new':
                        this.dsd.addPackage(this.state.FormData);
                        break;
                    case 'edit':
                        this.dsd.editPackage(this.state.idPackage,this.state.FormData);
                        break;
        //            case 'remove':
        //                this.savviconfig.removeFactor(this.data.idProject,this.type,this.data.idFactor);
        //                break;  
                    default:
                        break; 
                };
    
        };

        event.preventDefault();
    }

    handleMode(event) {
        this.setState({mode: event.target.value});
        event.preventDefault();
    }


    handleButton(event) {

        let StateChange = {};

        switch (event.target.name){
            case 'btnAddActor':
                this.props.navigation(`/package/${this.state.idPackage}/actor?mode=new`);
                break;
            case 'btnAddSystem':
                this.props.navigation(`/package/${this.state.idPackage}/system?mode=new`);
                break;
            case 'btnAddUseCase':
                this.props.navigation(`/package/${this.state.idPackage}/usecase?mode=new`);
                break;
            case 'btnAddAssociation':
                this.props.navigation(`/package/${this.state.idPackage}/association?mode=new`);
                break;
            
        }

        this.setState(StateChange);

        event.preventDefault();
    };

    handleVizClick(event) {

        let StateChange = {};

        let idNode = null;

        let target = null;
        if (event.target.tagName == "a"){
            target = event.target;
        } else{
            if ("parentElement" in event.target){
                if (event.target.parentElement.tagName == "a"){
                    target = event.target.parentElement;
                }
            }
        }

        if (target){
            if ("href" in target){
                idNode = target.href.baseVal;
                StateChange.vizNodeId = idNode;
            }
        }
        

        this.setState(StateChange);

        event.preventDefault();

    };



    visualise(){
    
        if (!this.state.Package){
            return <Buffering/>;
        }

        const styles = {
            scrollableDiv: {
                    backgroundColor: "#f1f1f1",
                    overflowX: "scroll",
                    whiteSpace: "nowrap",
                    height: "800px",
                    width: "800px",
                    overflow: "auto",
                    margin: "20px",
                    textAlign: "justify",
                    padding: "20px"
            }
          };

        let styleDiv = styles.scrollableDiv;
        if (this.state.Controls.hasOwnProperty('vizFullSize')){
            if (this.state.Controls.vizFullSize){
                styleDiv = null;
            }
        }

        try {

            const images = this.dsd.getActorImages();

            return(
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <PD.Checkbox label="Full Screen" name="vizFullSize" value={this.state.Controls.vizFullSize} onChange={this.handleChange}/>                    
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <>
                        <div style={styleDiv} onClick={this.handleVizClick}>
{//                            <D3Graphviz dot={this.viz.dot} images={[{filename:"images/stick.png", height:"50px", width:"50px"}]}/>
}
                            <D3Graphviz dot={this.viz.dot} images={images}/>

                    </div>
                    </>

                </div>
            );
          } catch (error) {
            return <div/>
          }
    };


    setLayoutContent(){

        if (this.context.hasLayout){
            
            let Content = {Index:{}};

            if (this.state.Package){
                Content.Index.title = this.state.Package.name;
                Content.Index.link = `/package/${this.state.Package._id}`

                Content.Index.Sections = [];

                if (this.state.Package.Actors){
                    let Section = {title:"Actors"}
                    Section.Items = [];
                    for (let posI=0;posI<this.state.Package.Actors.length; posI++){
                        let Actor = this.state.Package.Actors[posI];

                        switch (Actor.status){
                            case 'deprecated':
                                break;
                            default:

                                let Item = {}
                                Item.label = Actor.name;
                                Item.link = `/actor/${Actor._id}`
                                Section.Items.push(Item);
                                break;
                        }
                    }
                    Content.Index.Sections.push(Section);
                }


                if (this.state.Package.Systems){
                    let Section = {title:"Systems"}
                    Section.Items = [];
                    for (let pos=0;pos<this.state.Package.Systems.length; pos++){
                        let System = this.state.Package.Systems[pos];

                        switch (System.status){
                            case 'deprecated':
                                break;
                            default:

                                let Item = {}
                                Item.label = System.name;
                                Item.link = `/system/${System._id}`
                                Section.Items.push(Item);
                                break;
                        }
                    }
                    Content.Index.Sections.push(Section);
                }


                if (this.state.Package.UseCases){
                    let Section = {title:"Use Cases"}
                    Section.Items = [];
                    for (let posI=0;posI<this.state.Package.UseCases.length; posI++){
                        let UseCase = this.state.Package.UseCases[posI];

                        switch (UseCase.status){
                            case 'deprecated':
                                break;
                            default:

                                let Item = {}
                                Item.label = UseCase.name;
                                Item.link = `/usecase/${UseCase._id}`
                                Section.Items.push(Item);
                                break;
                        }
                    }
                    Content.Index.Sections.push(Section);
                }


                if (this.state.Package.Associations){
                    let Section = {title:"Associations"}
                    Section.Items = [];
                    for (let posC=0;posC<this.state.Package.Associations.length; posC++){
                        let Association = this.state.Package.Associations[posC];

                        switch (Association.status){
                            case 'deprecated':
                                break;
                            default:

                                let Item = {}
                                Item.label = Association.name;
                                Item.link = `/association/${Association._id}`
                                Section.Items.push(Item);
                                break;
                        }
                    }
                    Content.Index.Sections.push(Section);
                }
            }

        


            this.context.Layout.setLayoutContent(Content)

            localStorage.setItem("dsdContent",JSON.stringify(Content));
            

        }

        return;

    }


    showError(){


        if (this.state.invalidFields){
            return(
                <>
                    {
                        Object.keys(this.state.invalidFields).map(
                        (field, pos) => 
                            {
                                let error = this.state.invalidFields[field].error;
                                return <p>{field}:{error}</p>
                            }
                        )
                    }
                </>
            )
        }

        return "ERROR - Failed to Update";

    }


    render() {


        if (this.state.isUpdated){
            switch (this.state.mode){
                case 'remove':
                    this.props.navigation(this.FallBackNavigation);
                    return null;
                default:
                    break;
            };
        };

        if (this.state.isLoaded) {

            this.setLayoutContent();

            let boolOfferEdit = false;

            if (this.state.Package){
                this.state.FormData = this.state.Package;
                if (this.dsd.loggedon){
                    if (this.dsd.user.id == this.state.Package.idUser){
                        boolOfferEdit = true;
                    }
                }        
            }

            switch (this.state.mode){
                case 'new':
                case 'edit':

                    return (
                    <div>

                        <PD.Heading>{this.state.mode} Package</PD.Heading>
    
                        {this.state.isFailed &&
                            <PD.ErrorBox>{this.showError()}</PD.ErrorBox>
                        }
    
                        <form> 
                            <PD.Input label="Name" width="60" name="name" value={this.state.FormData.name} onChange={this.handleChange}/>

                            <PD.Editor label='Description' name="description" value={this.state.FormData.description} onChange={this.handleChange}/>

                            <PD.Editor label='Proposals' name="proposals" value={this.state.FormData.proposals} onChange={this.handleChange}/>

                            <PD.Select label="Status" name='status'  onChange={this.handleChange} value={this.state.FormData.status}>
                                {
                                    this.dsd.config.ComponentStatuses.map(
                                        (status, posStatus) => 
                                            {
                                                return(
                                                    <option key={posStatus} value={status}>{status}</option>
                                                )
                                            }
                                    )
                                }
                            </PD.Select>

                            <PD.Button type="submit" onClick={this.handleSubmit} name="btnSave"value="Save" />    
                            <PD.Button type="warning" onClick={this.handleSubmit} name="btnCancel" value="Cancel" />    
                        </form>
    
                    </div>    
                    );
                case 'remove':
                    return (
                        <div>
                            <PD.Heading>{this.state.mode} Package</PD.Heading>

                            {this.state.isFailed &&
                                <PD.ErrorBox>ERROR - Failed to Update</PD.ErrorBox>
                            }

                            <PD.Button onClick={this.handleSubmit} type="warning" value="confirm remove?" />
                            <ViewPackage json={this.state.Package}/>
                        </div>
                    )
                case 'view':
                default:

                    if (!this.state.Package){
                        return <div/>
                    }

                    let countActors = 0;
                    if (this.state.Package.Actors){
                        countActors = this.state.Package.Actors.length;
                    }
                    let countSystems = 0;
                    if (this.state.Package.Systems){
                        countSystems = this.state.Package.Systems.length;
                    }
                    let countUseCases = 0;
                    if (this.state.Package.UseCases){
                        countUseCases = this.state.Package.UseCases.length;
                    }
                    let countAssociations = 0;
                    if (this.state.Package.Associations){
                        countAssociations = this.state.Package.Associations.length;
                    }

                    this.viz = this.dsd.vizPackage({Package:this.state.Package}, this.state.Controls, this.vizEngine );

                    return (
                        <div>
                            <div>
                                <PD.Breadcrumb crumbs={this.Breadcrumb}/>
                                <PD.Heading>Package:{this.state.Package.name}</PD.Heading>
                            </div>

                            {boolOfferEdit &&
                                <span style={{paddingRight:10}}>
                                        <PD.Button onClick={this.handleMode} type="submit" value="edit" />                        
                                </span>
                            }

                            <ViewPackage Package={this.state.Package}/>

                            <br/>

                            <PD.Tabs>

                                <div label='Visualise'>
                                    <table>
                                        <tr>
                                            <td style={{verticalAlign: 'top', width: 'auto'}}>
                                                {this.visualise()}
                                            </td>
                                        </tr>
                                    </table>
                                </div>


                                <div label={`Actors(${countActors})`}>
                                    <div style={{display:"flex"}}>
                                        {boolOfferEdit && 
                                            <PD.Button name='btnAddActor' value='add a new Actor' onClick={this.handleButton}/>
                                        }
                                        <fieldset style={{display:"flex"}}>
                                            <legend>filters</legend>
                                            <PD.Select label='status' name='status'  onChange={this.handleActorFilters} value={this.state.ActorFilters.status}>
                                                {
                                                    this.dsd.config.ActorStatuses.map(
                                                        (status, posStatus) => 
                                                            {
                                                                return(
                                                                    <option key={posStatus} value={status}>{status}</option>
                                                                )
                                                            }
                                                    )
                                                }
                                            </PD.Select>
    
                                        </fieldset>
                                    </div>

                                    <ListActors filters={{idPackage:this.state.idPackage, ...this.state.ActorFilters}} parent={this} />

                                </div>

                                <div label={`Systems(${countSystems})`}>
                                    <div style={{display:"flex"}}>
                                        {boolOfferEdit && 
                                            <PD.Button name='btnAddSystem' value='add a new System' onClick={this.handleButton}/>
                                        }
                                        <fieldset style={{display:"flex"}}>
                                            <legend>filters</legend>
                                            <PD.Select label='status' name='status'  onChange={this.handleSystemFilters} value={this.state.SystemFilters.status}>
                                                {
                                                    this.dsd.config.SystemStatuses.map(
                                                        (status, posStatus) => 
                                                            {
                                                                return(
                                                                    <option key={posStatus} value={status}>{status}</option>
                                                                )
                                                            }
                                                    )
                                                }
                                            </PD.Select>
    
                                        </fieldset>
                                    </div>

                                    <ListSystems filters={{idPackage:this.state.idPackage, ...this.state.SystemFilters}} parent={this} />

                                </div>

                                <div label={`Use Cases(${countUseCases})`}>
                                    <div style={{display:"flex"}}>
                                        {boolOfferEdit && 
                                            <PD.Button name='btnAddUseCase' value='add a new Use Case' onClick={this.handleButton}/>
                                        }
                                        <fieldset style={{display:"flex"}}>
                                            <legend>filters</legend>
                                            <PD.Select label='status' name='status'  onChange={this.handleUseCaseFilters} value={this.state.UseCaseFilters.status}>
                                                {
                                                    this.dsd.config.UseCaseStatuses.map(
                                                        (status, posStatus) => 
                                                            {
                                                                return(
                                                                    <option key={posStatus} value={status}>{status}</option>
                                                                )
                                                            }
                                                    )
                                                }
                                            </PD.Select>
    
                                        </fieldset>
                                    </div>

                                    <ListUseCases filters={{idPackage:this.state.idPackage, ...this.state.UseCaseFilters}} parent={this} />

                                </div>



                                <div label={`Associations(${countAssociations})`}>
                                    <div style={{display:"flex"}}>
                                        {boolOfferEdit && 
                                            <PD.Button name='btnAddAssociation' value='add a new Association' onClick={this.handleButton}/>
                                        }
                                        <fieldset style={{display:"flex"}}>
                                            <legend>filters</legend>
                                            <PD.Select label='status' name='status'  onChange={this.handleAssociationFilters} value={this.state.AssociationFilters.status}>
                                                {
                                                    this.dsd.config.AssociationStatuses.map(
                                                        (status, posStatus) => 
                                                            {
                                                                return(
                                                                    <option key={posStatus} value={status}>{status}</option>
                                                                )
                                                            }
                                                    )
                                                }
                                            </PD.Select>
    
                                        </fieldset>
                                    </div>

                                    <ListAssociations filters={{idPackage:this.state.idPackage, ...this.state.AssociationFilters}} parent={this} />

                                </div>

                                <div label='Developer'>
                                    <table>
                                        {this.viz &&
                                            <tr>
                                                <th style={{verticalAlign:"top"}}>dot</th>
                                                <td><pre>{this.viz.dot}</pre></td>
                                            </tr>
                                        }
                                            <tr>
                                                <th style={{verticalAlign:"top"}}>csv</th>
                                                <td><pre>{this.state.csv}</pre></td>
                                            </tr>

                                    </table>

                                </div>


                                <div label='Metadata'>
                                    <ViewMetadata Data={this.state.Package} />
                                </div>



                                <div label="used in ...">
                                </div>


                            </PD.Tabs>


                        </div>
                    )
            };
        };

        return <div />;
    };
};

// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <Package {...props} navigation={navigation} location={location} params={params} />;
  }
