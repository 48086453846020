import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual, isArray } from "lodash";

import {cloneObject, getParam, formatText, truncate} from '../utils/utils';
import {SanitizeHTML,  HtmlToText} from '../utils/utils';

import dsd from "../classes/clsDSD";

import * as PD from '../classes/pdStyle';

class ListEntities extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            Entities : null
        };


        this.dsd = new dsd();
        this.dsd.StateObject = this;

    }

componentDidMount(){

    this.setup();
 
}


componentDidUpdate(prevProps, prevState){

   if (!isEqual(this.props, prevProps)){
       this.setup();
   }

   if (!isEqual(this.state, prevState)){

        if (this.state.load){
            this.loadData();
        }

        if (this.state.isLoaded){
            if (this.state.onLoad){
                this.state.onLoad(this.state.Entities.length);
            }
        }

    }
}

loadData(){

    this.dsd.listEntities(this.state.filters);
    this.setState({load:false});
}

setup(){

    let StateChange = {
        filters: {},
        data: null,
        columns: {
            owner:false,
            logicalmodel: false
        },
        load: false,
        onLoad: null

    }

    if (getParam('filters', this.props)){
        StateChange.filters = getParam('filters', this.props);
    }

    if (getParam('columns', this.props)){
        StateChange.columns = getParam('columns', this.props);
    }

    if (getParam('onLoad', this.props)){
        StateChange.onLoad = getParam('onLoad', this.props);
    }


    StateChange.load = true;

    this.setState(StateChange);
        
}



    included(Entity) {

        let IncludeThis = true;

        return IncludeThis;
    }

    render() {


        if (this.state.isFailed) {
            return (
                <PD.ErrorBox>
                    A problem has occurred
                </PD.ErrorBox>
            )
        }


        if (this.state.isLoaded) {
            let arrEntities = this.state.Entities;
            if (isArray(arrEntities)){
                if (arrEntities.length > 0){

                    return (
                        <PD.Table className="list">
                            <PD.Thead>
                                <PD.Tr>
                                    <PD.Th>Name</PD.Th>
                                    {this.state.columns.owner &&
                                        <PD.Th>Owner</PD.Th>                            
                                    }
                                    {this.state.columns.logicalmodel &&
                                        <PD.Th>Logical Model</PD.Th>                            
                                    }

                                    <PD.Th>Description</PD.Th>
                                </PD.Tr>
                            </PD.Thead>

                            <PD.Tbody>

                            {
                                arrEntities.map(
                                    (Entity, pos) => 
                                    {
                                        if (this.included(Entity)) {

                                            let maxRows = 1;

                                            let Rows = []
                                            for (let i = 1; i <= maxRows; i++) {
                                                Rows.push(null);
                                            }
                
                                            return (

                                                    <PD.Tr key={pos}>
                                                        <PD.Td>
                                                            <PD.PdNavLink to={"/entity/" + Entity._id} > {Entity.name}</PD.PdNavLink>
                                                        </PD.Td>
                                                        {this.state.columns.owner &&
                                                            <PD.Td>
                                                                {(() => {
                                                                    if (Entity.LogicalModel){
                                                                        if (Entity.LogicalModel.Owner){
                                                                            return Entity.LogicalModel.Owner.organisation;
                                                                        }
                                                                    }
                                                                    return null;
                                                                })()}
                                                            </PD.Td>
                                                        }
                                                        {this.state.columns.logicalmodel &&
                                                            <PD.Td>
                                                                {(() => {
                                                                    if (Entity.LogicalModel){
                                                                        return Entity.LogicalModel.name;
                                                                    }
                                                                    return null;
                                                                })()}
                                                            </PD.Td>
                                                        }
                                                        <PD.Td>
                                                            {formatText(truncate(HtmlToText(Entity.description), 400))}
                                                        </PD.Td>
                                                    </PD.Tr>

                                            )
                                        }
                                        else{
                                            return null;
                                        }                
                                    }
                                )
                            }
                            </PD.Tbody>

                        </PD.Table>
                    )
                }
            }
        }

        return <div />;

    };    
};
        
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <ListEntities {...props} navigation={navigation} location={location} params={params} />;
  }
