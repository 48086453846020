import React from "react"

import {hasProperty, formatText} from '../utils/utils';

import dsd from '../classes/clsDSD';

import * as PD from '../classes/pdStyle';

import {SanitizeHTML} from '../utils/utils';


class ViewRelationship extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            noLinks: false,
        };


        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.data = {};
        this.data.Relationship = null;

        if (this.props.Relationship){
            this.data.Relationship = this.props.Relationship; 
        }

        if (this.props.noLinks){
            this.state.noLinks = this.props.noLinks; 
        }

        this.filters = {};

        this.columns = {
        };

        this.data.id = null;
        if (this.props.id){
            this.data.id = this.props.id; 
        }


    }

    componentDidMount() {

        if (this.data.Relationship){
            this.setState({"isLoaded":true});
            return;
        }

    };

    view(json){

        if (json){
            return(
                <PD.Table className="panel">
                    <PD.Tbody>                                

                        {hasProperty(json,'EntityFrom') &&
                            <PD.Tr>
                                <PD.Th>Entity From</PD.Th>
                                <PD.Td>
                                    {(() => {
                                        switch (this.state.noLinks) {
                                            case true:
                                                return json.EntityFrom.name
                                            default:
                                                return (
                                                    <PD.PdNavLink to={"/entity/" + json.EntityFrom._id} >
                                                        {json.EntityFrom.name}
                                                    </PD.PdNavLink>
                                                )
                                        }
                                    })()}
                                </PD.Td>
                            </PD.Tr>
                        }

                        {hasProperty(json,'label') &&
                            <PD.Tr>
                                <PD.Th>Label</PD.Th>
                                <PD.Td>
                                    {json.label}
                                </PD.Td>
                            </PD.Tr>
                        }

                        {hasProperty(json,'EntityTo') &&
                            <PD.Tr>
                                <PD.Th>Entity To</PD.Th>
                                <PD.Td>
                                   {(() => {
                                        switch (this.state.noLinks) {
                                            case true:
                                                return json.EntityTo.name
                                            default:
                                                return (
                                                    <PD.PdNavLink to={"/entity/" + json.EntityTo._id} >
                                                        {json.EntityTo.name}
                                                    </PD.PdNavLink>
                                                )
                                        }
                                    })()} 
                               </PD.Td>
                            </PD.Tr>
                        }

                        {hasProperty(json,'cardinality') &&
                            <PD.Tr>
                                <PD.Th>Cardinality</PD.Th>
                                <PD.Td>
                                    {json.cardinality}
                                </PD.Td>
                            </PD.Tr>
                        }


                        {hasProperty(json,'description') &&
                            <PD.Tr>
                                <PD.Th>Description</PD.Th><PD.Td><SanitizeHTML html={json.description}/></PD.Td>
                            </PD.Tr>
                        }

                        {hasProperty(json,'proposals') &&
                            <PD.Tr>
                                <PD.Th>Proposals</PD.Th><PD.Td><SanitizeHTML html={json.proposals}/></PD.Td>
                            </PD.Tr>
                        }


                        {hasProperty(json,'status') &&
                            <PD.Tr>                                
                                <PD.Th>Status</PD.Th><PD.Td>{json.status}</PD.Td>
                            </PD.Tr>
                        }

                        {hasProperty(json,'LogicalModel') &&
                            <PD.Tr>                                
                                <PD.Th>Logical Model</PD.Th>
                                <PD.Td>
                                    {(() => {
                                        switch (this.state.noLinks) {
                                            case true:
                                                return json.LogicalModel.name
                                            default:
                                                return (
                                                    <PD.PdNavLink to={"/logicalmodel/" + json.LogicalModel._id} > {json.LogicalModel.name}</PD.PdNavLink>
                                                )
                                        }
                                    })()} 
                                </PD.Td>
                            </PD.Tr>
                        }
                    </PD.Tbody>
                </PD.Table>
            )
        };
        return null;
    };

    render() {

        if (this.state.isLoaded) {

            return (
                <div>
                    {this.view(this.data.Relationship)}
                </div>
            )
        }
        else {
            return <div />;
        }

    };    
};
        
export default ViewRelationship;