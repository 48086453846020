import React from 'react';

import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import {getParam} from '../utils/utils';
import { isNull, isEqual, isArray } from "lodash";



import * as d3Graphviz from 'd3-graphviz';

class D3Graphviz extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};

        this.divViz = React.createRef();

        this.setImages = this.setImages.bind(this);

    }

    componentDidMount(){
        this.setup(); 
     }

     componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        if (!isEqual(this.state, prevState)){
            if (this.divViz.current){
                this.createVizSvg();
            }
        }


     }
 

    setup(){

        let StateChange = {
            dot: null,
            options: {
                engine:"dot",
                fit:false,
                width:null,
                height:null,
                zoom:false,
                scale:1
            },
            images: [],
            FullSize : true
        }

        StateChange.dot = getParam('dot', this.props);
        if (getParam('options', this.props)){
            StateChange.options = getParam('options', this.props);
        }
        StateChange.images = getParam('images', this.props);

        this.setState(StateChange);
    }


    createVizSvg(){

/*
        d3Graphviz.graphviz(`#${this.divViz.current.id}`)
            .options(this.state.options)
            .addImage("images/stick.png", "50px", "50px")
            .renderDot(this.state.dot);
*/

        d3Graphviz.graphviz(`#${this.divViz.current.id}`).options(this.state.options)
        if (this.state.images.length){
            this.state.images.forEach((item) => (d3Graphviz.graphviz(`#${this.divViz.current.id}`).addImage(item.filename, item.height, item.width)))
        }
        d3Graphviz.graphviz(`#${this.divViz.current.id}`).renderDot(this.state.dot,this.setImages);



        return;

    }

    setImages(){

        if (this.divViz.current){
            if (this.divViz.current.childNodes[0]){
                if (this.divViz.current.childNodes[0].tagName == 'svg'){
                    let svg = this.divViz.current.childNodes[0];
                    this.findSvgImage(svg);
                }   
            }

        }
        return;
    }

    findSvgImage(Document){
        if ('tagName' in Document){
            if (Document.tagName == 'image'){
                this.setSvgImage(Document);
            }
        }
        if ('children' in (Document)){
            for (let pos=0; pos<Document.children.length; pos++){
                let Child = Document.children[pos];
                this.findSvgImage(Child);
            }
        }

        return;

    }

    setSvgImage(Document){

        if (Document.attributes){
            if ('href' in Document.attributes){
                let ImageName = Document.attributes.href.nodeValue;
                for (let pos=0; pos<this.state.images.length;pos++){
                    let Image = this.state.images[pos];
                    if (Image.filename == ImageName){
                        Document.attributes.href.nodeValue = Image.object;
                    }
                }
            }
        }

        return;
    }


    render(){

        return (
            <div ref={this.divViz} id="graph"/>
        )
// might need this                    <script src="https://unpkg.com/@hpcc-js/wasm/dist/graphviz.umd.js" type="javascript/worker"></script>

    }
}



export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <D3Graphviz {...props} navigation={navigation} location={location} params={params} />;
  }
