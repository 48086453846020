import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual, isArray } from "lodash";

import sanitizeHtml from 'sanitize-html';

import 'react-tabs/style/react-tabs.css';


import * as PD from '../classes/pdStyle';
import {HtmlToText, cloneObject, getParam} from '../utils/utils';
import {formatDate, formatText, truncate} from '../utils/utils';


import dsd from "../classes/clsDSD";

import { Graphviz } from "graphviz-react";
import ViewDataDictionary from "../components/ViewDataDictionary";
import ViewMetadata from "../components/ViewMetadata";
import ListStructures from "../components/ListStructures";
import ListTermTypes from "../components/ListTermTypes";

import Version from "../components/Version";
import ListVersions from "../components/ListVersions";
import ViewVersion from "../components/ViewVersion";


import { DesignerLayoutContext } from '../contexts/DesignerLayoutContext';

import Buffering from "../components/Buffering";

import { json2csv } from 'json-2-csv';

import JSZip from "jszip";


class DataDictionary extends React.Component {

    static contextType = DesignerLayoutContext;

    constructor(props) {
        super(props);
        this.state = {
            mode: 'view',
            isLoaded: false,
            loadData:false
        };


        this.data = {};
        this.viz = null;
        this.vizEngine = "dot";

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMode = this.handleMode.bind(this);
        this.handleButton = this.handleButton.bind(this);
        this.handleVizClick = this.handleVizClick.bind(this);

        this.handleStructureFilters = this.handleStructureFilters.bind(this);
        this.handleTermTypeFilters = this.handleTermTypeFilters.bind(this);

        this.handleExport = this.handleExport.bind(this);

        this.handleAddVersion = this.handleAddVersion.bind(this);
        this.VersionOnActioned = this.VersionOnActioned.bind(this);
        this.loadedVersions = this.loadedVersions.bind(this);
        this.loadedStructures = this.loadedStructures.bind(this);
        this.loadedTermTypes = this.loadedTermTypes.bind(this);

        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.FallBackNavigation = `/datadictionaries`;


    }

    componentDidMount(){

       this.setup();

    }


    componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){

            if (this.state.mode === 'new'){
                StateChange.isLoaded = true;
            }

            if (this.state.loadData){
                this.loadData(this.state.idDataDictionary);
                StateChange.updateToggle = Number(!this.state.updateToggle)
                StateChange.loadData = false;
            }

            if (this.state.isUpdated){
                StateChange.isUpdated = false;
                StateChange.mode = 'view';
                StateChange.loadData = true;
            }
            else{
                if (this.state.idDataDictionary != prevState.idDataDictionary){
                    if (this.state.idDataDictionary){
                        this.onUpdateNavigation = `/datadictionary/${this.state.idDataDictionary}`;
                        StateChange.loadData = true;
                    }
                }
            }

            if (this.state.isLoaded) {
    
                StateChange.OfferEdit = false;
    
                if (this.state.DataDictionary){
                    StateChange.FormData = this.state.DataDictionary;
                    if (this.dsd.loggedon){
                        if (this.dsd.user.id == this.state.DataDictionary.idUser){
                            StateChange.OfferEdit = true;
                        }
                    }        
                }
            }



            if (this.state.idDataDictionary){
                for (var i in window.Breadcrumb){
                    if (window.Breadcrumb[i][0] === 'datadictionary'){
                        window.Breadcrumb = window.Breadcrumb.slice(0,i);
                        break;
                    }
                }
                this.Breadcrumb = JSON.parse(JSON.stringify(window.Breadcrumb));
    
                window.Breadcrumb.push(['data dictionary',`/datadictionary/${this.state.idDataDictionary}`]);

                this.FallBackNavigation = `../datadictionaries`;
                this.onUpdateNavigation = this.FallBackNavigation;
    
            };

            this.makeCsv();

            this.setState(StateChange);

        }

    }
    
    loadData(id){

        if (id !== undefined){
            this.dsd.getDataDictionary(id);
        }
        
        return;
        
    };

    setup(){


        let StateChange = {
            id: null,
            isLoaded: false,
            isUpdated: false,
            isFailed: false,
            loadData: false,

            OfferEdit: false,

            FormData: {},
            Controls: {
                vizFullSize :true
            },
            StructureFilters: {
                status:'in use'
            },
            TermTypeFilters: {
                status:'in use'
            },

            countStructures : null,
            countTermTypes : null,

            DataDictionary: null,
            Structures: null,
            TermTypes: null,

            idVersion: null,

            action: null,

            updateToggle: 0,

            countVersions: 0,
            countStructures: 0,
            countTermTypes: 0,


            vizNodeId: null,

            csv: null

        }

        StateChange.mode = getParam('mode', this.props);
        StateChange.idDataDictionary = getParam('id', this.props);
        if (StateChange.idDataDictionary){
            StateChange.loadData = true;
        }

        if (StateChange.mode == null){
            StateChange.mode = 'view';
        }

        StateChange.idVersion = getParam('idVersion', this.props);

        this.setState(StateChange);
        
    }

    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let Controls = this.state.Controls;

        switch (FormFieldName){
            case 'vizFullSize':
                Controls[FormFieldName] = FormFieldValue;        
                this.setState({"Controls": Controls});
                break;
            default:
                let FormData = this.state.FormData;
                FormData[FormFieldName] = FormFieldValue;        
                this.setState({"FormData": FormData});
                break;                
        }
        return;

    };
    

    handleTermTypeFilters(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let TermTypeFilters = this.state.TermTypeFilters;
        TermTypeFilters[FormFieldName] = FormFieldValue;
        this.setState({"TermTypeFilters": TermTypeFilters});

        return;

    };

    handleStructureFilters(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let StructureFilters = this.state.StructureFilters;
        StructureFilters[FormFieldName] = FormFieldValue;
        this.setState({"StructureFilters": StructureFilters});

        return;

    };



    handleSubmit(event) {

        switch (event.target.name){
            case 'btnCancel':
                switch (this.state.mode){
                    case 'new':
                        this.props.navigation(this.FallBackNavigation);
                        break;
                    case 'edit':
                        this.loadData(this.state.idDataDictionary);
                        this.setState({mode: 'view'});
                        break;
        //            case 'remove':
        //                this.savviconfig.removeFactor(this.data.idProject,this.type,this.data.idFactor);
        //                break;  
                    default:
                        break; 
                };

                break;
            case 'btnSave':
            default:

                this.setState({
                    isUpdated: false,
                    isFailed: false,
                    invalidFields: false
                })
        

                if ('description' in this.state.FormData){
                    this.state.FormData.description = sanitizeHtml(this.state.FormData.description);
                }
                if ('proposals' in this.state.FormData){
                    this.state.FormData.proposals = sanitizeHtml(this.state.FormData.proposals);
                }

                switch (this.state.mode){
                    case 'new':
                        this.dsd.addDataDictionary(this.state.FormData);
                        break;
                    case 'edit':
                        this.dsd.editDataDictionary(this.state.idDataDictionary,this.state.FormData);
                        break;
        //            case 'remove':
        //                this.savviconfig.removeFactor(this.data.idProject,this.type,this.data.idFactor);
        //                break;  
                    default:
                        break; 
                };
    
        };

        event.preventDefault();
    }

    handleMode(event) {
        this.setState({mode: event.target.value});
        event.preventDefault();
    }


    handleButton(event) {

        let StateChange = {};

        switch (event.target.name){
            case 'btnAddStructure':
                this.props.navigation(`/datadictionary/${this.state.idDataDictionary}/structure?mode=new`);
                break;
            case 'btnAddTermType':
                this.props.navigation(`/datadictionary/${this.state.idDataDictionary}/termtype?mode=new`);
                break;
    
        }

        this.setState(StateChange);

        event.preventDefault();
    };

    handleAddVersion(event) {

        this.setState({newVersion:true});

    }


    handleVizClick(event) {

        let StateChange = {};

        let idNode = null;
        if ("href" in event.target){
            idNode = event.target.href.baseVal;
        }
        else{
            if ("parentElement" in event.target){
                if ("href" in event.target.parentElement){
                    idNode = event.target.parentElement.href.baseVal;
                }        
            }
        }
        

        StateChange.vizNodeId = idNode;

        this.setState(StateChange);

        event.preventDefault();

    };


    async handleExport(event){

        const zip = new JSZip();
        
        let DataDictionary = cloneObject(this.state.DataDictionary);
// remove deprecated items
        let Structures = [];
        if (DataDictionary.Structures){
            for (let pos=0; pos<DataDictionary.Structures.length; pos++){
                let Structure = DataDictionary.Structures[pos];
                switch (Structure.status){
                    case 'deprecated':
                        break;
                    default:
                        Structures.push(Structure);
                        break;
                }
                
            }
        }
        DataDictionary.Structures = Structures;

        let strDataDictionary = JSON.stringify(DataDictionary, null, 4);

        zip.file(`datadictionary_${this.state.idDataDictionary}.json`, strDataDictionary);

        let listStructures = {};
        for (let posS=0; posS<DataDictionary.Structures.length; posS++){
            let Structure = cloneObject(DataDictionary.Structures[posS]);

            Structure.DataDictionary = DataDictionary;
            listStructures[Structure._id] = Structure;

            let dotStructure = this.dsd.vizStructure({Structure:Structure}).dot;
            zip.file(`structure_${Structure._id}.dot`,dotStructure);

        }

        for (let idStructure in listStructures){
            let Structure = listStructures[idStructure];
            delete Structure.DataDictionary.Structures;
            
            let strStructure = JSON.stringify(Structure,null,4);
            zip.file(`structure_${Structure._id}.json`, strStructure);
        }

/*
        const rdfs = this.makeRdfs();
        zip.file(`conceptmodel_${this.state.idConceptModel}.ttl`,rdfs);
*/
        const zipData = await zip.generateAsync({
            type: "blob",
            streamFiles: true,
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(zipData);
        link.download = `datadictionary_${this.state.idDataDictionary}.zip`;
        link.click();

    }


    makeCsv(){

        if (!this.state.DataDictionary){
            return null;
        }

        if (!this.state.Structures){
            return null;
        }
    

        let rows = [];

        for (let posC=0;posC<this.state.Structures.length;posC++){
            const Structure = this.state.Structures[posC];
            let row = {};
            let rowPrefix = '';
            if (this.state.DataDictionary.prefix){
                rowPrefix = `${this.state.DataDictionary.prefix}:`
            }
            row.Structure = `${rowPrefix}${Structure.name.replace(/ /g,"_")}`;
            row.Label = Structure.name;
            row.Description = HtmlToText(Structure.description);
            rows.push(row);
        }


        rows.sort((a, b) => {
            const StructureA = a.Structure.toUpperCase();
            const StructureB = b.Structure.toUpperCase();
            if (StructureA < StructureB) {
              return -1;
            }
            if (StructureA > StructureB) {
              return 1;
            }
          
            // names must be equal
            return 0;
          });


        
        const options = [];
        const csv = json2csv(rows);
        this.setState({csv:csv});

        return true;

    };


    log(){

        if (!this.state.isLoaded){
            return null;
        }

        if (!this.state.DataDictionary){
            return null;
        }

        let arrLog = [];
        for (let pos=0;pos<this.state.DataDictionary.Structures.length;pos++){
            let Structure = this.state.DataDictionary.Structures[pos];
            if (Structure.createdAt){
                let LogEntry = {};
                LogEntry.date = Structure.createdAt;
                LogEntry.action = 'created';
                LogEntry.subjectType = 'Structure';
                LogEntry.Subject = Structure;
                LogEntry.name = Structure.name;
                LogEntry.link = `/structure/${Structure._id}`;
                arrLog = this.insertLog(arrLog, LogEntry);
            }
            if (Structure.Revisions){
                for (let posR=0; posR<Structure.Revisions.length;posR++){
                    let Revision = Structure.Revisions[posR];
                    if (Revision.report){
                        let LogEntry = {};
                        LogEntry.date = Revision.createdAt;
                        LogEntry.action = 'revised';
                        LogEntry.subjectType = 'Structure';
                        LogEntry.Subject = Structure;
                        LogEntry.name = Structure.name;
                        LogEntry.link = `/structue/${Structure._id}`;
                        LogEntry.Revision = Revision
                        LogEntry.description = Revision.description;
                        arrLog = this.insertLog(arrLog, LogEntry);
                    }
                }
            }
        }

        if (!arrLog.length){
            return null;
        }

        return (
            <PD.Table>
                <PD.Thead>
                    <PD.Tr>
                        <PD.Th>on</PD.Th>
                        <PD.Th>what</PD.Th>
                        <PD.Th>name</PD.Th>
                        <PD.Th>change</PD.Th>
                        <PD.Th>description</PD.Th>
                    </PD.Tr>
                </PD.Thead>

                <PD.Tbody>
                    {
                        arrLog.map(
                        (entry, pos) => 
                            {
                                return (
                                    <PD.Tr>
                                        <PD.Td>{formatDate(entry.date,'dmy')}</PD.Td>
                                        <PD.Td>{entry.subjectType}</PD.Td>
                                        <PD.Td>
                                            <PD.PdNavLink to={entry.link} >{entry.name}</PD.PdNavLink>
                                        </PD.Td>
                                        <PD.Td>{entry.action}</PD.Td>
                                        <PD.Td>{formatText(truncate(HtmlToText(entry.description), 400))}</PD.Td>
                                    </PD.Tr>
                                )
                            }
                        )
                    }
                </PD.Tbody>
            </PD.Table>

        )


    }

    insertLog(array, entry){

        let boolInserted = false;

        let newArray = [];

        for (let pos=0; pos<array.length;pos++){
            if (!boolInserted){
                if (entry.date < array[pos].date){
                    newArray.push(entry);
                    boolInserted = true;
                }
            }
            newArray.push(array[pos]);
        }

        if (!boolInserted){
            newArray.push(entry);
        }

        return newArray;

    }

    versions(){

        if (!this.state.isLoaded){
            return null;
        }

        if (!this.state.DataDictionary){
            return null;
        }

        let newVersion = false;
        let showAdd = false;

        if (this.state.OfferEdit){
            showAdd = true;
        }
        if (this.state.newVersion){
            showAdd = false;
            newVersion = true;
        }

        return (
            <div>
                {showAdd &&
                    <div style={{display:"flex"}}>
                        <PD.Button name='btnAdd' value='add a Named Version' onClick={this.handleAddVersion}/>
                    </div>
                }
                
                {newVersion &&
                    <Version mode='new' idAbout={this.state.idDataDictionary} type='datadictionary' onActioned={this.VersionOnActioned}/>
                }
                <ListVersions key={this.state.updateToggle} OfferEdit={this.state.OfferEdit} filters={{idAbout:this.state.idDataDictionary}} onLoad={this.loadedVersions}/>

            </div>
        )
    }

    VersionOnActioned(){
       this.setState(
        {
            newVersion:false,
            updateToggle: Number(!this.state.updateToggle)
        })
    }

    loadedVersions(count){

        let StateChange = {};

        StateChange.countVersions = 0;
        if (count){
            StateChange.countVersions = count;
        }

        this.setState(StateChange);

    }


    loadedStructures(count){

        let StateChange = {};

        StateChange.countStructures = 0;
        if (count){
            StateChange.countStructures = count;
        }

        this.setState(StateChange);

    }

    loadedTermTypes(count){

        let StateChange = {};

        StateChange.countTermTypes = 0;
        if (count){
            StateChange.countTermTypes = count;
        }

        this.setState(StateChange);

    }


    visualise(){
    
        if (!this.state.DataDictionary){
            return <Buffering/>;
        }

        const styles = {
            scrollableDiv: {
                    backgroundColor: "#f1f1f1",
                    overflowX: "scroll",
                    whiteSpace: "nowrap",
                    height: "800px",
                    width: "800px",
                    overflow: "auto",
                    margin: "20px",
                    textAlign: "justify",
                    padding: "20px"
            }
          };


        let vizoptions = {}
        vizoptions.engine = this.vizEngine;
        vizoptions.fit = false;
        vizoptions.width = null;
        vizoptions.height = null;
        vizoptions.zoom = false;
        vizoptions.scale = 1;

        let styleDiv = styles.scrollableDiv;
        if (this.state.Controls.hasOwnProperty('vizFullSize')){
            if (this.state.Controls.vizFullSize){
                styleDiv = null;
            }
        }

        try {

            return(
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <PD.Checkbox label="Full Screen" name="vizFullSize" value={this.state.Controls.vizFullSize} onChange={this.handleChange}/>                    
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div name="divViz" style={styleDiv} onClick={this.handleVizClick}>
                        <Graphviz dot={this.viz.dot} options={vizoptions}/>
                    </div>

                </div>
            );
          } catch (error) {
            return <div/>
          }
    };


    setLayoutContent(){

        if (this.context.hasLayout){
            
            let Content = {Index:{}};

            if (this.state.DataDictionary){
                Content.Index.title = this.state.DataDictionary.name;
                Content.Index.link = `/datadictionary/${this.state.DataDictionary._id}`

                Content.Index.Sections = [];

                if (this.state.DataDictionary.Structures){
                    let Section = {title:"Structures"}
                    Section.Items = [];
                    for (let posI=0;posI<this.state.DataDictionary.Structures.length; posI++){
                        let Structure = this.state.DataDictionary.Structures[posI];

                        switch (Structure.status){
                            case 'deprecated':
                                break;
                            default:

                                let Item = {}
                                Item.label = Structure.name;
                                Item.link = `/structure/${Structure._id}`
                                Section.Items.push(Item);
                                break;
                        }
                    }
                    Content.Index.Sections.push(Section);
                }


                if (this.state.DataDictionary.TermTypes){
                    let Section = {title:"Term Types"}
                    Section.Items = [];
                    for (let posI=0;posI<this.state.DataDictionary.TermTypes.length; posI++){
                        let TermType = this.state.DataDictionary.TermTypes[posI];

                        switch (TermType.status){
                            case 'deprecated':
                                break;
                            default:

                                let Item = {}
                                Item.label = TermType.name;
                                Item.link = `/termtype/${TermType._id}`
                                Section.Items.push(Item);
                                break;
                        }
                    }
                    Content.Index.Sections.push(Section);
                }



            }

            this.context.Layout.setLayoutContent(Content)

            localStorage.setItem("dsdContent",JSON.stringify(Content));
            

        }

        return;

    }

    showError(){


        if (this.state.invalidFields){
            return(
                <>
                    {
                        Object.keys(this.state.invalidFields).map(
                        (field, pos) => 
                            {
                                let error = this.state.invalidFields[field].error;
                                return <p>{field}:{error}</p>
                            }
                        )
                    }
                </>
            )
        }

        return "ERROR - Failed to Update";

    }



    render() {


        if (this.state.isUpdated){
            switch (this.state.mode){
                case 'remove':
                    this.props.navigation(this.FallBackNavigation);
                    return null;
                default:
                    break;
            };
        };

        if (this.state.isLoaded) {

            this.setLayoutContent();

            if (this.state.idVersion){
                return(
                    <ViewVersion idVersion={this.state.idVersion} type='datadictionary'/>
                )
            }


            switch (this.state.mode){
                case 'new':
                case 'edit':

                    return (
                    <div>

                        <PD.Heading>{this.state.mode} Data Dictionary</PD.Heading>
    
                        {this.state.isFailed &&
                            <PD.ErrorBox>{this.showError()}</PD.ErrorBox>
                        }
    
                        <form> 
                            <PD.Input label="Name" width="60" name="name" value={this.state.FormData.name} onChange={this.handleChange}/>

                            <PD.Editor label='Description' name="description" value={this.state.FormData.description} onChange={this.handleChange}/>

                            <PD.Input label="Namespace" width="30" name="namespace" value={this.state.FormData.namespace} onChange={this.handleChange}/>
                            <PD.Input label="Default Prefix" width="10" name="prefix" value={this.state.FormData.prefix} onChange={this.handleChange}/>

                            <PD.Editor label='Proposals' name="proposals" value={this.state.FormData.proposals} onChange={this.handleChange}/>

                            <PD.Select label="Status" name='status'  onChange={this.handleChange} value={this.state.FormData.status}>
                                {
                                    this.dsd.config.ComponentStatuses.map(
                                        (status, posStatus) => 
                                            {
                                                return(
                                                    <option key={posStatus} value={status}>{status}</option>
                                                )
                                            }
                                    )
                                }
                            </PD.Select>

                            <PD.Button type="submit" onClick={this.handleSubmit} name="btnSave"value="Save" />    
                            <PD.Button type="warning" onClick={this.handleSubmit} name="btnCancel" value="Cancel" />    
                        </form>
    
                    </div>    
                    );
                case 'remove':
                    return (
                        <div>
                            <PD.Heading>{this.state.mode} Data Dictionary</PD.Heading>

                            {this.state.isFailed &&
                                <PD.ErrorBox>ERROR - Failed to Update</PD.ErrorBox>
                            }

                            <PD.Button onClick={this.handleSubmit} type="warning" value="confirm remove?" />
                            <ViewDataDictionary json={this.state.DataDictionary}/>
                        </div>
                    )
                case 'view':
                default:

                    if (!this.state.DataDictionary){
                        return <div/>
                    }
                    
//                    const countItems = this.state.countItems == null ? "Loading..." : this.state.countItems;

//                    this.viz = this.dsd.vizDataDictionary({DataDictionary:this.state.DataDictionary}, this.state.Controls, this.vizEngine );

                    return (
                        <div>
                            <div>
                                <PD.Breadcrumb crumbs={this.Breadcrumb}/>
                                <PD.Heading>Data Dictionary:{this.state.DataDictionary.name}</PD.Heading>
                            </div>

                            {this.state.OfferEdit &&
                                <span style={{paddingRight:10}}>
                                        <PD.Button onClick={this.handleMode} type="submit" value="edit" />                        
                                </span>
                            }

                            <PD.Button onClick={this.handleExport} name="btnExport" value="export" />

                            <ViewDataDictionary DataDictionary={this.state.DataDictionary}/>

                            <br/>

                            <PD.Tabs>

                                <div label={`Structures(${this.state.countStructures})`}  forceRender={true}>
                                    <div style={{display:"flex"}}>
                                        {this.state.OfferEdit &&                           
                                            <PD.Button name='btnAddStructure' value='add a new Structure' onClick={this.handleButton}/>
                                        }
                                        <fieldset style={{display:"flex"}}>
                                            <legend>filters</legend>
                                            <PD.Select label='status' name='status'  onChange={this.handleStructureFilters} value={this.state.StructureFilters.status}>
                                                {
                                                    this.dsd.config.StructureStatuses.map(
                                                        (status, posStatus) => 
                                                            {
                                                                return(
                                                                    <option key={posStatus} value={status}>{status}</option>
                                                                )
                                                            }
                                                    )
                                                }
                                            </PD.Select>
    
                                        </fieldset>
                                    </div>
                                    <ListStructures filters={{idDataDictionary:this.state.idDataDictionary, ...this.state.StructureFilters}}  onLoad={this.loadedStructures} />
                                </div>

                                <div label={`Term Types(${this.state.countTermTypes})`}  forceRender={true}>
                                    <div style={{display:"flex"}}>
                                        {this.state.OfferEdit &&                           
                                            <PD.Button name='btnAddTermType' value='add a new Term Type' onClick={this.handleButton}/>
                                        }
                                        <fieldset style={{display:"flex"}}>
                                            <legend>filters</legend>
                                            <PD.Select label='status' name='status'  onChange={this.handleTermTypeFilters} value={this.state.TermTypeFilters.status}>
                                                {
                                                    this.dsd.config.TermTypeStatuses.map(
                                                        (status, posStatus) => 
                                                            {
                                                                return(
                                                                    <option key={posStatus} value={status}>{status}</option>
                                                                )
                                                            }
                                                    )
                                                }
                                            </PD.Select>
    
                                        </fieldset>
                                    </div>
                                    <ListTermTypes filters={{idDataDictionary:this.state.idDataDictionary,...this.state.TermTypeFilters}}  onLoad={this.loadedTermTypes} />
                                </div>

                                <div label='Log'>
                                    {this.log()}
                                </div>

                                <div label={`Versions(${this.state.countVersions})`}  forceRender={true}>
                                    {this.versions()}
                                </div>


                                <div label='Developer'>
                                    <table>
                                        {this.viz &&
                                            <tr>
                                                <th style={{verticalAlign:"top"}}>dot</th>
                                                <td><pre>{this.viz.dot}</pre></td>
                                            </tr>
                                        }
                                            <tr>
                                                <th style={{verticalAlign:"top"}}>csv</th>
                                                <td><pre>{this.state.csv}</pre></td>
                                            </tr>

                                    </table>

                                </div>


                                <div label='Metadata'>
                                    <ViewMetadata Data={this.state.DataDictionary} />
                                </div>



                                <div label="used in ...">
                                </div>


                            </PD.Tabs>


                        </div>
                    )
            };
        };

        return <div />;
    };
};

// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <DataDictionary {...props} navigation={navigation} location={location} params={params} />;
  }
