import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual, isArray } from "lodash";

import {cloneObject, getParam, formatText, truncate} from '../utils/utils';
import {SanitizeHTML,  HtmlToText} from '../utils/utils';

import dsd from "../classes/clsDSD";

import * as PD from '../classes/pdStyle';

class ListLinks extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            Links : null
        };

        this.dsd = new dsd();
        this.dsd.StateObject = this;

    }

componentDidMount(){

    this.setup();
 
}


componentDidUpdate(prevProps, prevState){

   if (!isEqual(this.props, prevProps)){
       this.setup();
   }

   if (!isEqual(this.state, prevState)){

        if (this.state.load){
            this.loadData();
        }

        if (this.state.isLoaded){
            if (this.state.onLoad){
                this.state.onLoad(this.state.Links.length);
            }
        }
    }
}

loadData(){

    this.dsd.listLinks(this.state.filters);
    this.setState({load:false});
}

setup(){

    let StateChange = {
        filters: {},
        data: null,
        columns: {
            owner:false,
            conceptmodel: false,
            conceptFrom: true,
            conceptTo: true,
            status: false

        },
        load: false,
        onLoad: null,

    }

    if (getParam('filters', this.props)){
        StateChange.filters = getParam('filters', this.props);
    }

    if (getParam('columns', this.props)){
        StateChange.columns = getParam('columns', this.props);
    }

    if (getParam('onLoad', this.props)){
        StateChange.onLoad = getParam('onLoad', this.props);
    }

    StateChange.load = true;

    this.setState(StateChange);
        
}



    included(Concept) {

        let IncludeThis = true;

        return IncludeThis;
    }

    render() {


        if (this.state.isFailed) {
            return (
                <PD.ErrorBox>
                    A problem has occurred
                </PD.ErrorBox>
            )
        }


        if (this.state.isLoaded) {
            let arrLinks = this.state.Links;
            if (isArray(arrLinks)){
                if (arrLinks.length > 0){

                    return (
                        <PD.Table className="list">
                            <PD.Thead>
                                <PD.Tr>

                                    {this.state.columns.conceptFrom &&
                                        <PD.Th>Concept From</PD.Th>                            
                                    }

                                    <PD.Th>Link</PD.Th>

                                    {this.state.columns.conceptTo &&
                                        <PD.Th>Concept To</PD.Th>                            
                                    }

                                    {this.state.columns.status &&
                                        <PD.Th>Status</PD.Th>                            
                                    }

                                    {this.state.columns.owner &&
                                        <PD.Th>Owner</PD.Th>                            
                                    }
                                    {this.state.columns.conceptmodel &&
                                        <PD.Th>Concept Model</PD.Th>                            
                                    }
                                    <PD.Th>Description</PD.Th>
                                </PD.Tr>
                            </PD.Thead>

                            <PD.Tbody>

                            {
                                arrLinks.map(
                                    (Link, pos) => 
                                    {
                                        if (this.included(Link)) {

                                            let maxRows = 1;

                                            let Rows = []
                                            for (let i = 1; i <= maxRows; i++) {
                                                Rows.push(null);
                                            }
                
                                            return (

                                                    <PD.Tr key={pos}>
                                                        {this.state.columns.conceptFrom &&
                                                            <PD.Td>
                                                                {(() => {
                                                                    if (Link.ConceptFrom){
                                                                        return Link.ConceptFrom.name;
                                                                    }
                                                                    return <div>not permitted</div>;
                                                                })()}
                                                           </PD.Td>
                                                        }

                                                        <PD.Td>
                                                            <PD.PdNavLink to={"/link/" + Link._id} > {Link.name}</PD.PdNavLink>
                                                        </PD.Td>

                                                        {this.state.columns.conceptTo &&
                                                            <PD.Td>
                                                                {(() => {
                                                                    if (Link.ConceptTo){
                                                                        return Link.ConceptTo.name;
                                                                    }
                                                                    return <div>not permitted</div>;
                                                                })()}
                                                           </PD.Td>
                                                        }

                                                        {this.state.columns.status &&
                                                            <PD.Td>
                                                                {Link.status}
                                                            </PD.Td>
                                                        }

                                                        {this.state.columns.owner &&
                                                            <PD.Td>
                                                                {(() => {
                                                                    if (Link.ConceptModel){
                                                                        if (Link.ConceptModel.Owner){
                                                                            return Link.ConceptModel.Owner.organisation;
                                                                        }
                                                                    }
                                                                    return null;
                                                                })()}
                                                            </PD.Td>
                                                        }
                                                        {this.state.columns.owner &&
                                                            <PD.Td>
                                                                {(() => {
                                                                    if (Link.ConceptModel){
                                                                        return Link.ConceptModel.name;
                                                                    }
                                                                    return null;
                                                                })()}
                                                            </PD.Td>
                                                        }
                                                        <PD.Td>
                                                            {formatText(truncate(HtmlToText(Link.description), 400))}
                                                        </PD.Td>
                                                    </PD.Tr>

                                            )
                                        }
                                        else{
                                            return null;
                                        }                
                                    }
                                )
                            }
                            </PD.Tbody>

                        </PD.Table>
                    )
                }
            }
        }

        return <div />;

    };    
};
        
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <ListLinks {...props} navigation={navigation} location={location} params={params} />;
  }
