import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual } from "lodash";

import sanitizeHtml from 'sanitize-html';


import 'react-tabs/style/react-tabs.css';

import * as PD from '../classes/pdStyle';
import {cloneObject, getParam} from '../utils/utils';

import dsd from "../classes/clsDSD";

import { Trash } from 'react-bootstrap-icons';

class Version extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isFailed: false
        };


        this.data = {};

        this.handleChange = this.handleChange.bind(this);
        this.handleButton = this.handleButton.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);


        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.FallBackNavigation = `.`;


    }

    componentDidMount(){

       this.setup();

    }

    componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){

            if (this.state.loadVersion){
                this.loadVersion();
            }

            if (this.state.isLoaded){
                if (this.state.Version){
                StateChange.FormData = this.state.Version;
                    if (this.state.mode == "new"){
                        StateChange.FormData.type = this.state.type;
                    }
                }
            }

            if (this.state.isUpdated){
                StateChange.isUpdated = false;
                if (this.state.onActioned){
                    this.state.onActioned();
                }
                else{
                    StateChange.mode = 'view';
                    StateChange.loadData = true;
                }
            }
    
            this.setState(StateChange);

        }

    }
    
    setup(){

        let StateChange = {

            loadVersion: false,
            isLoaded:false,

            FormData: {
            },

            Version: null,
            idVersion: null,

            idAbout: null,
            type: null,

            onLoad: null,

            mode: null

        }

        StateChange.mode = getParam('mode', this.props);
        StateChange.idAbout = getParam('idAbout', this.props);

        if (StateChange.mode == 'new'){
            StateChange.Version = {};
            StateChange.isLoaded = true;
        }

        StateChange.idVersion = getParam('idVersion', this.props);
        if (StateChange.idVersion){
            StateChange.mode = 'edit';
        }

        if (StateChange.idVersion){
            StateChange.loadVersion = true;
        }

        if (getParam('onActioned', this.props)){
            StateChange.onActioned = getParam('onActioned', this.props);
        }
    
        if (getParam('type', this.props)){
            StateChange.type = getParam('type', this.props);
        }

        this.setState(StateChange);
        
    }


    loadVersion(){

        let StateChange = {};
        StateChange.loadVersion = false;
        StateChange.isLoaded = false;
        StateChange.Version = null;

        if (this.state.idVersion){
            StateChange.renderState = "loading";
            this.dsd.getVersion(this.state.idVersion);
        }

        this.setState(StateChange);

        return;
        
    };

    handleButton(event) {
    
        let StateChange = {};

        switch (event.currentTarget.name){
            case 'btnDelete':
                this.dsd.removeVersion(this.state.idVersion);
                break;
    
        }
    
        this.setState(StateChange);
    
        event.preventDefault();    

    };


    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let FormData = this.state.FormData;
        FormData[FormFieldName] = FormFieldValue;

        this.setState({"FormData": FormData});

    };


    handleSubmit(event) {

        switch (event.target.name){
            
            case 'btnCancel':
        
                if (this.state.onActioned){
                    this.state.onActioned();
                }
    

                break;

            case 'btnSave':
    
                this.update();
        
                break;
    
        };
    }

    update(){    

        if (!this.state.isLoaded){
            return false;
        }


        if ('description' in this.state.FormData){
            this.state.FormData.description = sanitizeHtml(this.state.FormData.description);
        }

        switch (this.state.mode){
            case 'new':
                this.dsd.addVersion(this.state.idAbout,this.state.FormData);
                break;
            case 'edit':
                this.dsd.editVersion(this.state.idVersion,this.state.FormData);
                break;
            default:
                return false;
        };

        return true;

    }

    render() {

        if (this.state.isFailed) {
            return <PD.ErrorBox>ERROR - Failed to Load</PD.ErrorBox>
        }
        if (this.state.isError) {
            return <PD.ErrorBox>ERROR - Failed to Load</PD.ErrorBox>
        }

        if (this.state.isLoaded) {

            switch (this.state.mode){
                case 'new':
                case 'edit':
                    return (
                        <fieldset>
                            <legend>Version:</legend>
                            <PD.Input label="Name" width="40" name="name" value={this.state.FormData.name} onChange={this.handleChange}/>
                            <PD.Editor label='Description' name="description" value={this.state.FormData.description} onChange={this.handleChange}/>

                            <div>
                                <PD.Button type="submit" onClick={this.handleSubmit} name="btnSave" value="Save" />    
                                <PD.Button type="warning" onClick={this.handleSubmit} name="btnCancel" value="Cancel" /> 
                            </div>

                        </fieldset>
                    );
                    break;
            };
        };

        return null;
    };
};

// Wrap and export
// with forwardref so that the update() method can be accessed.


export default React.forwardRef((props, ref) => {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <Version ref={ref} {...props} navigation={navigation} location={location} params={params} />;
  }
);